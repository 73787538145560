import calc from "../../assets/images/calculators/calc.png";

export const Resourcedata = [
  {
    id: 1,
    image: calc,
    title: "GST Calculator",
    desc: "Calculate your GST payment and input tax credit quickly and accurately",
    path: "/calculators/gst_calculators",
  },
  {
    id: 2,
    image: calc,
    title: "Bizdateup Equity Calculator",
    desc: "Easily Calculate Equity Shares and Ownership Percentage with Bizdateup.",
    path: "/calculators/equity_calculator",
  },
  {
    id: 3,
    image: calc,
    title: " Equity Dilution Calculator",
    desc: "Calculate Your Ownership Stake in a Company",
    path: "/calculators/equity_dilution_calculator",
  },
  {
    id: 4,
    image: calc,
    title: "Startup Investment Calculator",
    desc: "Calculate Your Tax Liability on Startup Investments with Ease",
    path: "/calculators/startup_investment_calculator",
  },

  {
    id: 5,
    image: calc,
    title: "Equity Investment Calculator ",
    desc: "Calculate Your Returns on Equity Investments with Ease",
    path: "/calculators/equity_investment_calculator",
  },
  {
    id: 6,
    image: calc,
    title: "Investment Returns Calculator",
    desc: "Calculate Your Investment Returns with Ease",
    path: "/calculators/Investment_return_calculator",
  },
  {
    id: 7,
    image: calc,
    title: "Venture Capital ROI Calculator",
    desc: "Calculate Your Potential Returns on Venture Capital Investment",
    path: "/calculators/venture_roi_calculator",
  },
  {
    id: 8,
    image: calc,
    title: "Revenue Growth Calculator",
    desc: "Calculate Your Business Growth Potential",
    path: "/calculators/revenue_growth_calculator",
  },
  {
    id: 9,
    image: calc,
    title: "Burn Rate Calculator",
    desc: "Calculate Your Startup's Burn Rate with Our Easy-to-Use Calculator",
    path: "/calculators/Burn_rate_calculator",
  },
  {
    id: 10,
    image: calc,
    title: "Debt to Equity Ratio Calculator",
    desc: "Calculate your D/E Ratio online and find out your company's leverage",
    path: "/calculators/Debt_to_equity_calculator",
  },
  {
    id: 11,
    image: calc,
    title: "Employee Equity Calculator",
    desc: "Calculate Your Equity Share in a Few Clicks",
    path: "/calculators/Employee_equity_calculator",
  },
  {
    id: 12,
    image: calc,
    title: "Investment Portfolio Calculator",
    desc: "Evaluate Your Investment Portfolio Performance with Bizdateup's Portfolio Calculator",
    path: "/calculators/Investment_portfolio_calculator",
  },
  {
    id: 13,
    image: calc,
    title: "Discounted Cash Flow (DCF) ",
    desc: "Valuation from Discounted Cash Flow (DCF) Method Calculator",
    path: "/calculators/discounted_cash_flow_calculator",
  },
  {
    id: 14,
    image: calc,
    title: "Market Multiple Method ",
    desc: "Calculate Your Company's Valuation Based on Earnings and Market Multiples",
    path: "/calculators/market_multiple_method_calculator",
  },
  {
    id: 15,
    image: calc,
    title: " Scorecard Method Calculator",
    desc: "Estimate the value of your startup using the Scorecard Method",
    path: "/calculators/scorecard_calculator",
  },
  {
    id: 16,
    image: calc,
    title: "Berkus Method Calculator",
    desc: "Calculate the Valuation of Your Startup with the Berkus Method",
    path: "/calculators/berkus_calculator",
  },
  {
    id: 17,
    image: calc,
    title: "Cap Table Calculator",
    desc: "Calculate Ownership & Equity Shares of Your Startup",
    path: "/calculators/cap_table_calculator",
  },

  {
    id: 18,
    image: calc,
    title: "Convertible Note Calculator",
    desc: "Calculate Your Investment Return on Convertible Notes",
    path: "/calculators/convertible_note_calculator",
  },
  {
    id: 19,
    image: calc,
    title: "Term Sheet Calculator",
    desc: "Create a Professional Term Sheet for Your Startup in Minutes",
    path: "/calculators/Term_sheet_calculator",
  },
  {
    id: 20,
    image: calc,
    title: "IRR Calculator",
    desc: "Calculate the Internal Rate of Return (IRR) on your Investments",
    path: "/calculators/irr_calculator",
  },
  {
    id: 21,
    image: calc,
    title: "EBITDA Calculator",
    desc: "Calculate EBITDA of Your Business with Ease",
    path: "/calculators/ebitda_calculator",
  },
  {
    id: 22,
    image: calc,
    title: "Cash Flow Calculator",
    desc: "Analyze Your Business's Cash Flow Situation With Our Free Calculator",
    path: "/calculators/cash_flow_calculator",
  },
  {
    id: 23,
    image: calc,
    title: "Business Breakeven Calculator",
    desc: "Calculate Break Even Point for Your Business.",
    path: "/calculators/break_even_calculator",
  },
  {
    id: 24,
    image: calc,
    title: "ROI Calculator",
    desc: " Calculate Your Return on Investment (ROI) with Bizdateup",
    path: "/calculators/roi_calculator",
  },
];
