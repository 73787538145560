export const bytesdata = [
  {
    id: 1,
    title: "How to be a startup investor",
    desc: "Learn the essentials of startup investing in this concise guide, covering strategies, risk management, and finding promising ventures.",
    youtubtlink: "https://www.youtube.com/embed/2pmIAsETyf0",
    videolink: "https://youtu.be/2pmIAsETyf0",
  },
  {
    id: 2,
    title: "How To Track Your Investment",
    desc: "Discover the art of investment tracking with a step-by-step guide of tips and tools for managing your portfolio effectively, ensuring successful investment decisions",
    youtubtlink: "https://www.youtube.com/embed/AzX72PXqgUk",
    videolink: "https://youtu.be/AzX72PXqgUk",
  },
  {
    id: 3,
    title: "Key difference between convertible and safe notes",
    desc: "Key distinctions between convertible and safe notes, demystifying their unique roles in startup financing. With benefits and implications of instruments for investors.",
    youtubtlink: "https://www.youtube.com/embed/H9MSEINNwoc",
    videolink: "https://youtu.be/H9MSEINNwoc",
  },
  {
    id: 4,
    title: "5 Common Myths about Startup Investment",
    desc: "The top 5 myths about startup investments that have been dispelled by professionals, clearing things up for both investors and prospective business owners.",
    youtubtlink: "https://www.youtube.com/embed/IYsiw4ZEKsY",
    videolink: "https://youtu.be/IYsiw4ZEKsY",
  },
  {
    id: 5,
    title: "Top Reasons to Switch to Non-Traditional Investing Methods",
    desc: "Why non-traditional investing methods are gaining popularity, offering higher returns and advantages of venturing beyond conventional investments for growth.",
    youtubtlink: "https://www.youtube.com/embed/LIA2aVuoAiI",
    videolink: "https://youtu.be/LIA2aVuoAiI",
  },
  {
    id: 6,
    title: "Red Flags Investor's should not Overlook",
    desc: "Red Flags that investors must be vigilant about to avoid potential risks and make informed decisions with strategies to safeguard investments.",
    youtubtlink: "https://www.youtube.com/embed/ONdBqslGZPs",
    videolink: "https://youtu.be/ONdBqslGZPs",
  },
];