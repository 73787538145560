import React from "react";
import frame5 from "../../Images/frame5.png";
import { AiOutlineRight } from "react-icons/ai";
import { IoIosSquare } from "react-icons/io";
import { NavLink } from "react-router-dom";

import herosection from "../../assets/images/herosection.png";
import image1 from "../../assets/images/layout2/image 319.svg";
import image2 from "../../assets/images/layout2/image-removebg-preview (52) 2.svg";
import image3 from "../../assets/images/layout2/image-removebg-preview (52) 2 (1).svg";
import dotgrid from "../../assets/images/layout2/ornament.svg";
import dotgrid2 from "../../assets/images/layout2/ornament (1).svg";

import heroosade1 from "../../Images/heroosade1.png";
import heroosade2 from "../../Images/heroosade2.png";

import main from "../../assets/images/layout2/98 57845788.svg";
const Section2 = () => {
  return (
    <section>
      {/* <div className="bg-[#240253] overflow-hidden relative md:pt-[100px] md:pb-[100px] pt-[44px] w-full font-[Inter]">
        <div className="md:flex justify-center relative mx-auto md:max-w-[1540px] items-center md:h-[509px] h-[803px]">
          <div className="md:flex justify-center items-center md:w-[50%] w-full md:m-auto md:pl-[120px] md:px-0 px-[16px] text-[#FFFFFF]">
            <div className="w-full">
              <div className="flex md:justify-start mb-[4px] items-center">
                <div className="text-[#FC9D35]">
                  <IoIosSquare size={15} />
                </div>
                <div className="ml-[7px]">
                  <h4 className="text-[13.33px] text-[#ffffff] font-[500] md:font-[600] leading-[19.99px]">
                    STARTUP INVESTING
                  </h4>
                </div>
              </div>
              <h4 className="md:font-[600] font-[500] md:text-[48px] text-[30px] md:leading-[72px] leading-[45px]">
                Investing in Indian Startups Start with just ₹1,00,000
              </h4>
              <p className="md:font-[400] font-[400] md:pt-[18px] pt-[30px] md:pr-[70px] pr-[40px] md:text-[16px] text-[16px] md:leading-[30px] leading-[24px] md:pb-[43px] pb-[30px] tracking-[2.4%]">
                Our platform makes it easy for you to invest in a variety of
                promising startups, with the potential for high returns on your
                small investments. Start investing in Indian startups today!
              </p>
              <div className="md:text-start text-center flex justify-start w-full">
                <NavLink to="/signup">
                  <button className="text-[#ffffff] md:px-6 md:py-[10px] py-[7px] px-6 border-[#ffffff] md:rounded-none  border-[1px] hover:bg-[#3e1c7d] duration-200 bg-[#F3B517]  md:w-auto">
                    <h4 className="flex items-center justify-center md:px-[25px]">
                      Sign Up Now
                    </h4>
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="md:w-[45%] flex justify-center items-center w-full">
            <div className="flex justify-center md:w-[494px] md:h-[374px] items-center pt-[80px] z-30">
              <img src={herosection} alt="" className="w-full" />
            </div>
          </div>
          <div className="lg:block hidden absolute opacity-[60%] w-[331px] h-[216px] top-[161px] left-[800px] bottom-0 z-10">
            <img src={heroosade1} alt="" className="w-full" />
          </div>
          <div className="lg:block hidden absolute z-10 opacity-[60%] w-[579px] h-[92px] top-[344px] left-[850px] right-0">
            <img src={heroosade2} alt="" className="w-full" />
          </div>
        </div>
        <div className="md:block hidden absolute left-0 top-0">
          <img src={frame5} alt="" />
        </div>
      </div> */}

      {/* css optimize */}
      <div className="bg-[#240253] overflow-hidden relative md:pt-[100px] md:pb-[100px] pt-[44px] w-full font-[Inter]">
        <div className="md:flex justify-center relative mx-auto md:max-w-[1540px] items-center md:h-[509px] h-[803px]">
          <div className="md:flex justify-center items-center md:w-[50%] w-full md:m-auto md:pl-[120px] md:px-0 px-[16px] text-[#FFFFFF]">
            <div className="w-full">
              <div className="flex md:justify-start mb-[4px] items-center">
                <div className="text-[#FC9D35]">
                  <IoIosSquare size={15} />
                </div>
                <div className="ml-[7px]">
                  <h4 className="text-[13.33px] text-[#ffffff] font-[500] md:font-[600] leading-[19.99px]">
                    STARTUP INVESTING
                  </h4>
                </div>
              </div>
              <h4 className="md:font-[600] font-[500] md:text-[48px] text-[30px] md:leading-[72px] leading-[45px]">
                Investing in Indian Startups Start with just ₹1,00,000
              </h4>
              <p className="md:font-[400] font-[400] md:pt-[18px] pt-[30px] md:pr-[70px] pr-[40px] md:text-[16px] text-[16px] md:leading-[30px] leading-[24px] md:pb-[43px] pb-[30px] tracking-[2.4%]">
                Our platform makes it easy for you to invest in a variety of
                promising startups, with the potential for high returns on your
                small investments. Start investing in Indian startups today!
              </p>
              <div className="md:text-start text-center flex justify-start w-full">
                <NavLink to="/signup">
                  <button className="text-[#ffffff] md:px-6 md:py-[10px] py-[7px] px-6 border-[#ffffff] md:rounded-none  border-[1px] hover:bg-[#3e1c7d] duration-200 bg-[#F3B517]  md:w-auto">
                    <h4 className="flex items-center justify-center md:px-[25px]">
                      Sign Up Now
                    </h4>
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
          <div className=" md:w-[45%] flex justify-center items-center w-full my-8 md:my-0">
            <div className="relative flex justify-center md:w-[494px] md:h-[374px] w-full h-[374px] items-center md:mb-8 z-30">
              <div className="absolute">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="329"
                  height="346"
                  viewBox="0 0 329 346"
                  fill="none"
                >
                  <g filter="url(#filter0_d_3413_34872)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M147.383 64.3547C170.081 71.8193 192.906 72.3838 215.508 80.1359C249.149 91.6743 304.434 85.7338 311.399 120.61C318.739 157.358 260.049 172.256 240.263 204.081C227.538 224.548 234.874 253.857 217.912 270.977C199.762 289.295 172.154 290.659 147.383 297.823C110.994 308.346 71.373 342.826 39.333 322.62C7.90102 302.796 17.1427 253.194 17.7033 216.037C18.1165 188.655 39.4647 166.951 42.0929 139.691C45.9809 99.3661 3.53575 44.8357 36.4827 21.2615C69.5452 -2.39525 108.763 51.6542 147.383 64.3547Z"
                      fill="#9797FE"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d_3413_34872"
                      x="0.0117188"
                      y="0.342773"
                      width="328.02"
                      height="345.51"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="1" />
                      <feGaussianBlur stdDeviation="8" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_3413_34872"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_3413_34872"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
              <div className="absolute z-10">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="400"
                  height="356"
                  viewBox="0 0 400 356"
                  fill="none"
                >
                  <g filter="url(#filter0_d_3413_34875)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M208.605 0.0469241C236.712 1.17712 253.204 33.0596 279.432 43.229C312.225 55.944 355.949 41.4614 380.463 66.6823C404.307 91.213 399.4 131.703 398.672 165.905C397.955 199.594 391.187 232.493 376.291 262.718C360.732 294.286 342.398 327.328 311.142 343.504C280.208 359.513 242.611 357.033 208.605 349.5C178.212 342.767 157.956 315.44 129.321 303.229C95.518 288.814 50.1934 298.901 25.7032 271.503C1.44985 244.37 -7.50513 199.465 6.57041 165.905C21.1347 131.179 70.4558 128.174 97.0136 101.478C114.758 83.6405 115.793 54.3208 134.411 37.3977C155.255 18.4513 180.459 -1.0848 208.605 0.0469241Z"
                      fill="#5C428C"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d_3413_34875"
                      x="-16.1211"
                      y="-15"
                      width="431.697"
                      height="387.38"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="1" />
                      <feGaussianBlur stdDeviation="8" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_3413_34875"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_3413_34875"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
              <img src={main} className="z-20  " />
              <div className=" absolute z-20 left-4 top-[120px] flex w-[107px] h-[63px] justify-center items-center gap-[9.205px] shrink-0 shadow-[0px_13.80782699584961px_28.766305923461914px_0px_rgba(0,0,0,0.15)] p-[13.808px] rounded-[13.808px] bg-[#dbc8ff]">
                <img src={image1} />
              </div>
              <div className=" absolute z-20 right-0 top-[130px] flex w-[107px] h-[63px] justify-center items-center gap-[9.205px] shrink-0 shadow-[0px_13.80782699584961px_28.766305923461914px_0px_rgba(0,0,0,0.15)] p-[13.808px] rounded-[13.808px] bg-[#dbc8ff]">
                <img src={image2} />
              </div>
              <div className=" absolute z-20 bottom-[0px] right-[100px] flex w-[107px] h-[63px] justify-center items-center gap-[9.205px] shrink-0 shadow-[0px_13.80782699584961px_28.766305923461914px_0px_rgba(0,0,0,0.15)] p-[13.808px] rounded-[13.808px] bg-[#dbc8ff]">
                <img src={image3} />
              </div>
              <div className="absolute z-20 left-4 top-[200px]">
                <img src={dotgrid} />
              </div>
              <div className="absolute z-20 right-14 top-5">
                <img src={dotgrid2} />
              </div>
            </div>
          </div>
          <div className="lg:block hidden absolute  w-[331px] h-[216px] top-[161px] left-[800px] bottom-0 z-10">
            {/* <img src={heroosade1} alt="" className="w-full" /> */}
            <div className="w-[231px] h-[216px] shrink-0  blur-[120px] bg-[#6933d3] " />
          </div>
          <div className="lg:block hidden absolute z-20  w-[579px] h-[92px] top-[344px] left-[850px] right-0">
            {/* <img src={heroosade2} alt="" className="w-full" /> */}
            <div className="w-[579px] h-[92px] shrink-0  blur-[80px] rounded-[579px] bg-[#6933d3]" />
          </div>
        </div>
        <div className="md:block hidden absolute left-0 top-0">
          <img src={frame5} alt="" />
        </div>
      </div>
    </section>
  );
};

export default Section2;
