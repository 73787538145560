import React from "react";
import "./loaderApi.css";

const LoaderApi = () => {
  return (
    <div class="loaderApi-container">
      <div class="loaderApi"></div>
      <div class="loaderApi"></div>
    </div>
  );
};

export default LoaderApi;
