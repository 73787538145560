import Image1 from "../../assets/images/image1.png";
import Image2 from "../../assets/images/image2.png";
import Image3 from "../../assets/images/image3.png";

export const sliderData = [
  {
    title: "Invest in Tomorrow's Success Today",
    desc: "Discover the power of startup investing and diversify your portfolio like the millionaires do.",
    image: Image1,
  },
  {
    title: "Join the World of Startup Investments",
    desc: "Unlock new opportunities and grow your wealth with our platform",
    image: Image2,
  },
  {
    title: "Invest in Game-Changing Ideas",
    desc: "Be a part of something big and support the next generation of innovators and entreprenuers.",
    image: Image3,
  },
];
